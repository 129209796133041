<style scoped>
.images {
  cursor: pointer;
  display: flex;
}
.images img{
  max-width: 300px;
  width: 100%;
  cursor: pointer;
}
</style>
<template>
  <div>
    <h2 class="text-center p-b-5">
      {{taskTitle}}
    </h2>
    <Row class="p-b-10">
      <i-col span="12"><label class="title m-r-5">客户</label>{{approvalInfo.userName}}</i-col>
    </Row>

        <Card  title="素材清单">
          <div class="p-t-10">
            <p v-if="approvalInfo.desc">
              <strong  class="title m-r-5">上传备注：</strong>{{approvalInfo.desc}}
            </p>
            <p v-if="approvalInfo.fileName">
              <strong  class="title m-r-5">素材分组</strong>{{approvalInfo.fileName}}
            </p>
            <!-- <p v-if="approvalInfo.fileTypeName">
              <strong  class="title m-r-5">素材类型</strong>{{approvalInfo.fileTypeName}}
            </p> -->

            <Tabs value="material" class="text-white">
              <TabPane label="上刊素材" name="material">
                <div class="images clearfix">
                  <Row :gutter="8" style="width: 100%">
                    <i-col
                      :xs="24"
                      :sm="12"
                      :md="8"
                      :lg="6"
                      v-for="(image, imgIndex) in material"
                      :key="imgIndex"
                    >
                      <img
                        :src="image.fileKey"
                        :alt="image.fileName"
                        class="image"
                        :key="image.fileKey"
                      />
                    </i-col>
                  </Row>
                </div>
              </TabPane>
              <TabPane label="资质材料" name="annex">
                <Table
                size="small"
                :columns="annexColumns"
                :data="annex"
                stripe
              ></Table>

              </TabPane>
            </Tabs>
            <!-- <template v-if="approvalVideo.length">
              <Divider dashed>视频</Divider>
              <Row :gutter="8">
                <i-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(video,videoIndex) in approvalVideo" :key="videoIndex">
                  <card class="text-center" style="word-break: break-all;word-wrap: break-word;">
                    <a @click="handleVideoPreview(video.fileKey)">{{video.fileName}}</a> <span
                      class="text-green">({{video.duration}}秒)</span>
                  </card>
                </i-col>
              </Row>
            </template>
            <template v-if="approvalVoice.length">
              <Divider dashed>音频</Divider>
              <Row :gutter="8">
                <i-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(voice,voiceIndex) in approvalVoice" :key="voiceIndex">
                  <card class="text-center" style="word-break: break-all;word-wrap: break-word;">
                    <a @click="handleVideoPreview(voice.fileKey)">{{voice.fileName}}</a> <span
                      class="text-green">({{voice.duration}}秒)</span>
                  </card>
                </i-col>
              </Row>
            </template> -->
          </div>
        </Card>

    <!-- 上刊材料预览（画面、视频） -->
    <Modal title="画面预览" v-model="visibleImage" width="1000" :footer-hide="true">
      <img-light-box-component ref="imgLightBox" :imgIndex.sync="imgIndex"></img-light-box-component>
    </Modal>

    <Modal title="音视频预览" v-model="visibleVideo" :footer-hide="true">
      <video :src="VideoUrl" controls="controls" style="width:100%;min-height:150px;">
        您的浏览器不支持预览视频。
      </video>
    </Modal>
    <!-- 上刊材料预览（画面、视频） -->
  </div>

</template>

<script>
import { getapprovalinfo } from '@/api/order/orderfile'
import { downloadFileRequest } from '@/utils/download'
import ImgLightBoxComponent from '@/components/common/ImgLightBox'

export default {
  props: {
    id: [Number, String],
    task: {
      type: Object
    }
  },
  components: {
    // ContractOrdersComponent,
    ImgLightBoxComponent
  },
  data () {
    return {
      defaultValue: '2',
      approvalInfo: {},

      approvalImage: [],
      approvalVideo: [],
      approvalVoice: [],
      approvalFile: [],
      approvalFileColumn: [
        { title: '文件名称', key: 'fileName' },
        { title: '文件类型', key: 'mimeType' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }

              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ],
      visibleImage: false,
      imgIndex: 0,
      visibleVideo: false,
      VideoUrl: '',
      downloadFileName: '',
      uploadDesc: '',
      contract: {}, // 合同信息
      annexColumns: [
        { title: '名称', key: 'realFileName' },
        { title: '文件类型', key: 'mimeType' },
        { title: '文件大小（KB）', key: 'length' },
        { title: '创建时间', key: 'createTime' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }

              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ],
      material: [],
      annex: []
    }
  },
  methods: {
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/om/v1/orderfile/downloadfile', { fileId: file.id }, file.fileName)
    },

    handleVideoPreview (fileUrl) {
      this.VideoUrl = fileUrl
      this.visibleVideo = true
    },
    formatImageUrl (fileUrl, width, height) {
      const widthstr = (width === 0) ? '' : ',w_' + width
      const heightstr = (height === 0) ? '' : ',h_' + height

      return fileUrl + '?x-oss-process=image/resize,m_lfit' + widthstr + heightstr
    },
    getApprovalDetails () {
      const _this = this
      _this.approvalScreen = []
      _this.approvalFile = []
      const data = {
        approvalId: _this.id
      }
      // getapprovalinfo(data).then(res => {
      //   res.forEach(file => {
      //     if (file.type === 1) {
      //       _this.approvalImage.push(file)
      //     } else if (file.type === 2) {
      //       _this.approvalFile.push(file)
      //     } else if (file.type === 3) {
      //       _this.approvalVideo.push(file)
      //     } else if (file.type === 4) {
      //       _this.approvalVoice.push(file)
      //     }
      //   })
      // })
      getapprovalinfo(data).then(res => {
        this.approvalInfo = res
        this.material = res.fileVOList.filter(x => x.type === 1)
        this.annex = res.fileVOList.filter(x => x.type === 2)
      })
    }
  },
  created () {
    this.getApprovalDetails()
  },
  computed: {
    taskTitle () {
      return this.task.processtypeName
    },
    isWarning () {
      return this.task.processtype === 10 && this.task.processSupplementFeature === 1
    }
  }
}
</script>
