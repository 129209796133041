<style scoped>
.images {
  cursor: pointer;
  display: flex;
}
.images img {
  max-width: 300px;
  width: 100%;
  cursor: pointer;
}
</style>
<template>
  <div>
    <h2 class="text-center p-b-5">
      {{ taskTitle }} <Tag v-if="isWarning" color="primary">待补充</Tag>
    </h2>
    <Row class="p-b-10">
      <i-col span="24"
        ><label class="title m-r-5">客户</label
        >{{ task.props.advertiserName }}</i-col
      >
      <i-col span="24"
        ><label class="title m-r-5">品牌</label
        >{{ task.props.brandName }}</i-col
      >
    </Row>

    <Collapse simple v-model="defaultValue">
      <Panel
        name="1"
        v-if="task.props.contractId "
      >
        <b>采购媒体折扣清单</b>
        <template slot="content">
          <!-- <i-table
            stripe
            size="small"
            :data="displaceMediaList"
            :columns="displaceMediaColumns"
          ></i-table>
          <div class="paging_style">
          <Page size="small" :total="total" :page-size="resourceQuery.pageSize" show-total show-elevator :current="resourceQuery.pageNumber" @on-change="handlePageChanged"></Page>
        </div> -->
          <i-table stripe size="small" :data="displaceMediaTypeList" :columns="displaceMediaTypeColumns"></i-table>
        </template>
      </Panel>

      <Panel
        name="2"
        v-if="task.props.contractId "
      >
        <b>服务费采购清单</b>
        <template slot="content">
          <!-- <i-table
            stripe
            size="small"
            :data="displaceMediaList"
            :columns="displaceMediaColumns"
          ></i-table>
          <div class="paging_style">
          <Page size="small" :total="total" :page-size="resourceQuery.pageSize" show-total show-elevator :current="resourceQuery.pageNumber" @on-change="handlePageChanged"></Page>
        </div> -->

        <i-table stripe size="small" :data="displaceServiceList" :columns="displaceServiceColumns"></i-table>
        </template>
      </Panel>

      <Panel
        name="3"
        v-if="task.props.contractId "
      >
        <b>采购媒体清单</b>
        <template slot="content">
          <i-table
            stripe
            size="small"
            :data="displaceMediaList"
            :columns="displaceMediaColumns"
          ></i-table>
          <div class="paging_style">
          <Page size="small" :total="total" :page-size="resourceQuery.pageSize" show-total show-elevator :current="resourceQuery.pageNumber" @on-change="handlePageChanged"></Page>
        </div>
        </template>
      </Panel>
      <Panel name="3">
        <b>素材列表</b>
        <Card slot="content">
          <i-button
            slot="extra"
            v-if="task.status === 1"
            type="warning"
            @click="handleDownload"
            >下载画面审批模板</i-button
          >
          <div class="p-t-10">
            <p v-if="uploadDesc !== ''">
              <strong>上传备注：</strong>{{ uploadDesc }}
            </p>

            <template v-if="approvalImage.length">
              <Divider dashed>画面</Divider>
              <div v-viewer class="images clearfix">
                <Row :gutter="8" style="width: 100%">
                  <i-col
                    :xs="24"
                    :sm="12"
                    :md="8"
                    :lg="6"
                    v-for="(image, imgIndex) in approvalImage"
                    :key="imgIndex"
                  >
                    <img
                      :src="image.fileKey"
                      :alt="image.fileName"
                      class="image"
                      :key="image.fileKey"
                    />
                    <p style="width: 200px">{{ image.fileName }}</p>
                  </i-col>
                </Row>
              </div>
            </template>
            <template v-if="approvalVideo.length">
              <Divider dashed>视频</Divider>
              <Row :gutter="8">
                <i-col
                  :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="6"
                  v-for="(video, videoIndex) in approvalVideo"
                  :key="videoIndex"
                >
                  <card
                    class="text-center"
                    style="word-break: break-all; word-wrap: break-word"
                  >
                    <a @click="handleVideoPreview(video.fileKey)">{{
                      video.fileName
                    }}</a>
                    <span class="text-green">({{ video.duration }}秒)</span>
                  </card>
                </i-col>
              </Row>
            </template>
            <template v-if="approvalVoice.length">
              <Divider dashed>音频</Divider>
              <Row :gutter="8">
                <i-col
                  :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="6"
                  v-for="(voice, voiceIndex) in approvalVoice"
                  :key="voiceIndex"
                >
                  <card
                    class="text-center"
                    style="word-break: break-all; word-wrap: break-word"
                  >
                    <a @click="handleVideoPreview(voice.fileKey)">{{
                      voice.fileName
                    }}</a>
                    <span class="text-green">({{ voice.duration }}秒)</span>
                  </card>
                </i-col>
              </Row>
            </template>
          </div>
        </Card>
      </Panel>
      <Panel name="3" v-if="approvalFile.length">
        <b>资质文件列表</b>
        <template slot="content">
          <i-table
            stripe
            :columns="approvalFileColumn"
            :data="approvalFile"
            size="small"
          ></i-table>
        </template>
      </Panel>
    </Collapse>

    <!-- 上刊材料预览（画面、视频） -->
    <Modal
      title="画面预览"
      v-model="visibleImage"
      width="1000"
      :footer-hide="true"
    >
      <img-light-box-component
        ref="imgLightBox"
        :imgIndex.sync="imgIndex"
      ></img-light-box-component>
    </Modal>

    <Modal title="音视频预览" v-model="visibleVideo" :footer-hide="true">
      <video :src="VideoUrl" controls="controls" style="width: 100%;min-height:150px;">
        您的浏览器不支持预览视频。
      </video>
    </Modal>
    <!-- 上刊材料预览（画面、视频） -->
  </div>
</template>

<script>
import { fileByApproval, getApproval } from '@/api/order/orderfile'
import { downloadFileRequest } from '@/utils/download'
import { getProductDetailPageByOrder } from '@/api/order/productdetail'
import { listDiscount, listService } from '@/api/displace/contract'
import ImgLightBoxComponent from '@/components/common/ImgLightBox'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    id: [Number, String],
    task: {
      type: Object
    }
  },
  components: {
    ImgLightBoxComponent
  },
  data () {
    return {
      defaultValue: '3',
      approvalImage: [],
      approvalVideo: [],
      approvalVoice: [],
      approvalFile: [],
      approvalFileColumn: [
        { title: '文件名称', key: 'fileName' },
        { title: '文件类型', key: 'mimeType' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }

              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ],
      visibleImage: false,
      imgIndex: 0,
      visibleVideo: false,
      VideoUrl: '',
      downloadFileName: '',
      uploadDesc: '',
      contract: {}, // 合同信息

      displaceMediaList: [],
      displaceMediaColumns: [
        { title: '资源编号', key: 'resourceCode' },
        { title: '资源类型', key: 'resourcetypeName' },
        { title: '所属资产', key: 'assetName' },
        { title: '所属站点', key: 'stationName' },
        {
          title: '投放档期',
          key: 'schedule',
          render: (h, data) => {
            return h('div', [
              h('span', data.row.startDate),
              h(
                'span',
                ' 至 '
              ),
              h('span', data.row.endDate)
            ])
          }
        },
        {
          title: '媒体刊例',
          key: 'usePrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.useprice))
            ])
          }
        }
      ],
      displaceServiceList: [],
      displaceServiceColumns: [
        { title: '服务费名称', key: 'serviceName' },
        {
          title: '刊例数量',
          key: 'useQuantity',
          render: (h, data) => {
            return h('div', [
              h('span', data.row.useQuantity + data.row.quantityName)
            ])
          }
        },
        {
          title: '刊例总价',
          key: 'useTotalPrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.useTotalPrice))
            ])
          }
        },
        {
          title: '采购数量',
          key: 'quantity',
          render: (h, data) => {
            return h('div', [
              h('span', data.row.quantity + data.row.quantityName)
            ])
          }
        },
        {
          title: '采购总价',
          key: 'totalPrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.totalPrice))
            ])
          }
        }
      ],

      displaceMediaTypeList: [],
      displaceMediaTypeColumns: [
        { title: '媒体类型', key: 'goodName' },
        { title: '采购数量', key: 'number' },
        {
          title: '媒体刊例',
          key: 'usePrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.usePrice))
            ])
          }
        },
        {
          title: '执行价',
          key: 'discountPrice',
          render: (h, data) => {
            return h('div', [
              h(
                'span', { class: 'money' },
                this.formatMoney(data.row.discountPrice)
              ),
              h(
                'span',
                { class: 'discount' },
                '（' + data.row.discount + '折）'
              )
            ])
          }
        }
      ],
      total: 0,
      resourceQuery: {
        pageNumber: 1,
        pageSize: 10
      }
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/v1/orderfile/downloadfile', { fileId: file.id }, file.fileName)
    },
    handleDownload () { // 下载画面审批模板
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/v1/orderfile/installpicture/download', { approvalId: this.id }, this.downloadFileName)
    },

    handlePageChanged (page) {
      this.resourceQuery.pageNumber = page
      this.initMediaArray()
    },

    // 获取采购的服务费信息
    initlistService () {
      // 获取采购的服务费信息
      listService({ contractId: this.task.props.contractId }).then(res => {
        this.displaceServiceList = res
      })
    },
    // 获取媒体列表
    initMediaArray () {
      this.resourceQuery.orderId = this.task.props.orderId
      getProductDetailPageByOrder(this.resourceQuery).then(res => {
        this.displaceMediaList = res.list
        this.total = res.totalRow
      })
    },
    // 获取合同设置的媒体折扣
    initMediaTypeArray () {
      listDiscount({ contractId: this.task.props.contractId }).then(res => {
        this.displaceMediaTypeList = res
      })
    },

    // handlePreview (imgIndex) {
    //   // let viewer = new Viewer(document.getElementById('a_img_' + imgIndex + ''), {
    //   //   url: this.approvalImage[imgIndex].fileKey
    //   // })
    //   // viewer.show()
    //   const postData = {
    //     startIndex: imgIndex,
    //     imgList: this.approvalImage.map(x => x.fileKey)
    //   }
    //   this.$refs.imgLightBox.init(postData)
    //   this.visibleImage = true
    // },
    handleVideoPreview (fileUrl) {
      this.VideoUrl = fileUrl
      this.visibleVideo = true
    },
    formatImageUrl (fileUrl, width, height) {
      const widthstr = (width === 0) ? '' : ',w_' + width
      const heightstr = (height === 0) ? '' : ',h_' + height

      return fileUrl + '?x-oss-process=image/resize,m_lfit' + widthstr + heightstr
    },
    getApprovalDetails () {
      const _this = this
      _this.approvalScreen = []
      _this.approvalFile = []
      const data = {
        approvalId: _this.id
      }
      fileByApproval(data).then(res => {
        res.forEach(file => {
          if (file.type === 1) {
            _this.approvalImage.push(file)
          } else if (file.type === 2) {
            _this.approvalFile.push(file)
          } else if (file.type === 3) {
            _this.approvalVideo.push(file)
          } else if (file.type === 4) {
            _this.approvalVoice.push(file)
          }
        })
      })
      getApproval(data).then(res => {
        this.uploadDesc = res.desc
      })
    },
    handleInitedStatement (params) {
      let fileName = '画面审批表('
      fileName += params.advertiserName
      fileName += params.code
      fileName += ').doc'

      this.downloadFileName = fileName
    },
    handleInitedContract (params) {
      this.contract = params

      let fileName = '画面审批表('
      fileName += params.firstParty
      fileName += params.code
      fileName += ').doc'

      this.downloadFileName = fileName
      this.$refs.ContractOrders.initPageData(params.contractId, params.versionId)
    }
  },
  created () {
    this.getApprovalDetails()
    this.initMediaTypeArray()
    this.initMediaArray()
    this.initlistService()
  },
  computed: {
    taskTitle () {
      let title = '画面审批'

      if (this.task.props.pictureTypeDesc) {
        title = this.task.props.pictureTypeDesc
      }
      return title
    },
    isWarning () {
      return this.task.processtype === 10 && this.task.processSupplementFeature === 1
    }
  }
}
</script>
